<template>
	<div>
		<exhibition-data-box :dialog.sync="dialog" :width="888" title="时段管理" :contentHeight="fixedHeight + 90">
			<common-table
					:itemScope="['timeRange', 'controltypeText', 'policytypeText']"
					:key="new Date().getTime()"
					:headers="headers"
					:listData="listData"
					:loading="loading"
					:dialogTable=true
					ref="ct"
					pageTitle="时段管理"
					@search="search"
					:fixedHeight="fixedHeight"
					:rightConfig="rightConfig"
					:normalPage="false"
					@remove="remove"
					:leftConfig="leftConfig"
					:btnAction="true"
					:btnActionEvent="btnActionEvent"
					@otherRightOperate="otherRightOperate"
					@otherTableOperate="otherTableOperate">
				<template v-slot:timeRange="{ item }">
					{{ item.startdate }} ~ {{ item.enddate }}
				</template>
				<template v-slot:controltypeText="{ item }">
					{{ controlText(item.controltype) }}
				</template>
				<template v-slot:policytypeText="{ item }">
					{{ policyText(item.policytype) }}
				</template>
			</common-table>
		</exhibition-data-box>
		<template v-if="addDialog">
			<form-menu
					:center="true"
					:center_btn="true"
					:listDeploy="listDeploy"
					height="650px"
					width="400px"
					:ridDivision="true"
					:dialog.sync="addDialog"
					rightbtn_text="确定"
					leftbtn_text="取消"
					@resetForm="addDialog = false"
					@validateSave="validateSave"
					ref="formRef"
					:title="title"/>
		</template>
	</div>
</template>

<script>
import ExhibitionDataBox from "@/components/exhibition-data-box/exhibition-data-box";
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/productManage/rule-timeRanage-manage.js';
import formMenu from "@/components/form-menu/form-menu.vue";
import rangeTime from '@/components/dateTime/rangeTime.vue';

export default {
	name: "timeRange-manage",
	components: {
		ExhibitionDataBox,
		formMenu
	},
	mixins: [commonCurd],
	props: {
		timeDialog: Boolean
	},
	data() {
		return {
			api,
			dialog: false,
			title: '',
			btnActionEvent: [
				{
					event: 'editItem1',
					icon: 'bianji2-fill',
					color: 'primary',
					tooltip: '编辑'
				},
				{
					event: 'deleteItem',
					icon: 'shanchu-fill',
					color: 'var(--v-redness-base)',
					tooltip: '删除'
				}
			],
			fixedHeight: 0,
			headers: [
				{text: '时段代码', value: 'code'},
				{text: '时段范围', value: 'timeRange'},
				{text: '时段描述', value: 'description'},
				{text: '判断类型', value: 'controltypeText'},
				{text: '策略影响', value: 'policytypeText'},
				{text: '操作', value: 'actions'}
			],
			rightConfig: [
				{label: '新建', icon: 'zengjia', event: 'add1'}
			],
			leftConfig: [
				{type: 'input', label: '输入时段代码/名称', property: 'searchkey', width: '200px'}
			],
			addDialog: false,
			listDeploy: {
				row: [
					{
						cols: 12,
						subassembly: rangeTime,
						property1: 'rangeTime',
						property: ["startdate", "enddate"],
						// 解构函数，应用场景：当一个组件的返回值要应用在多个表单属性上时候使用
						deconstruction: function (args) {
							return args.split(" ~ ")
						},
						connector: {
							props: {
								required: true,
								label: "有效日期范围",
							},
						},
					},
					{
						cols: 12,
						subassembly: "VTextField",
						property: 'description',
						connector: {
							props: {
								required: true,
								label: "时段描述"
							}
						}
					},
					{
						cols: 12,
						subassembly: "WAutocomplete",
						property: 'controltype',
						connector: {
							props: {
								required: true,
								label: "判断类型",
								clearable: false,
								items: [{
									desc: '使用日期在控制时间内',
									code: 0
								}, {
									desc: '控制时间内到达',
									code: 1
								}]
							}
						}
					},
					{
						cols: 12,
						subassembly: 'VTextarea',
						property: 'script',
						connector: {
							props: {
								solo: true,
								rows: 2,
								outlined: true,
								flat: true,
								placeholder: '输入判断表达式',
								height: '120px'
							}
						}
					},
					{
						cols: 12,
						subassembly: 'div',
						connector: {
							class: 'form-row-title',
							domProps: {
								innerHTML: '策略影响'
							}
						}
					},
					{
						cols: 12,
						subassembly: "WAutocomplete",
						property: 'policytype',
						connector: {
							props: {
								required: true,
								label: "策略影响",
								clearable: false,
								items: [{
									desc: '取消限制',
									code: 0
								}, {
									desc: '购买限制',
									code: 1
								}],
							}
						}
					},
				]
			}
		}
	},
	watch: {
		timeDialog: {
			handler(v) {
				if (v) {
					this.dialog = true;
				}
			},
			immediate: true
		},
		dialog: {
			handler(v) {
				if (!v) {
					this.$emit("closeTimeDialog");
				}
			}
		}
	},
	mounted() {
		this.fixedHeight = document.documentElement.clientHeight * 0.6;
	},
	methods: {
		otherRightOperate(event) {
			if (event === 'add1') {
				this.listDeploy.sqlid = 0
				for (const obj of this.listDeploy.row) {
					delete obj.default
					if (obj.connector?.props) {
						obj.connector.props.disabled = false
						delete obj.connector.props.value
					}
				}
				this.title = '新建时段'
				this.addDialog = true
			}
		},
		validateSave(item, callback) {
			if (item.done) {
				item.form.sqlid = this.listDeploy.sqlid;
				this.axios.post(this.api.save, item.form).then(r => {
					if (r.code === this.staticVal.Code.Success) {
						this.snackbar.success("保存成功");
						callback();
						this.list();
					} else {
						this.$refs.formRef.load = false;
					}
				});
			}
		},
		async otherTableOperate(event, object) {
			const sqlid = object.sqlid
			if (event === this.btnActionEvent[0].event) {
				const item = await this.getObject(sqlid)
				this.listDeploy.sqlid = sqlid;
				for (const obj of this.listDeploy.row) {
					if (!obj.property) {
						continue
					}
					if (obj.property1 === 'rangeTime') {
						obj.default = item.startdate + ' ~ '  + item.enddate
					} else {
						obj.default = item[obj.property]
					}
					if (obj.hasEditDisabledProperty) {
						obj.connector.props.disabled = true;
					}
				}
				this.title = '编辑时段'
				this.addDialog = true
			}
		},
		controlText(value) {
			switch (value) {
				case 0:
					return '使用日期在控制时间内'
				case 1:
					return '控制时间内到达'
			}
		},
		policyText(value) {
			switch (value) {
				case 0:
					return '取消限制'
				case 1:
					return '购买限制'
			}
		}
	}
}
</script>

<style scoped>

</style>
