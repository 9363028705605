<template>
	<div>
		<c-title title="购买时间限制" sub-title="（限制下单的时间范围）" style="display: inline-block"/>
		<w-switches label="" style="display: inline-block !important;" v-model="buyTimeLimit"/>
		<v-row v-if="buyTimeLimit">
			<v-col cols="6">
				<range-time-picker
						:value="initValue"
						:required="required"
						@change="$emit('change', $event)"
						startText="购买开始时间"
						endText="购买结束时间"
				/>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import cTitle from "./title.vue";
import rangeTimePicker from "@/components/timePicker/rangeTimePicker.vue";

export default {
	// 购买的时间限制
	name: "time-limit",
	components: {cTitle, rangeTimePicker},
	props: {
		value: {
			type: String,
			default: ''
		},
		required: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			buyTimeLimit: true,
			initValue: ''
		}
	},
	watch: {
		value: {
			handler(v) {
				if (v) {
					this.initValue = v;
					this.buyTimeLimit = !!v;
				} else {
					this.buyTimeLimit = false
				}
			},
			immediate: true
		},
		buyTimeLimit: {
			handler(v) {
				this.$emit('change', v ? this.initValue : null);
			}
		}
	}
}
</script>

<style scoped>

</style>
