<template>
	<div :class="clazz">
		<span class="asterisk">
			<label class="c-title">{{ title }}</label>
		</span>
		<span class="sub-title">{{ subTitle }}</span>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: ''
		},
		subTitle: {
			type: String,
			default: ''
		},
		clazz: {
			type: String,
			default: ''
		}
	}
}
</script>

<style scoped>
.c-title {
	font-size: 18px;
	font-weight: bold;
}

.sub-title {
	font-size: 14px;
	color: #999999;
	margin-left: 8px;
}
</style>
