export default {
    props: {
        // 选项卡中的表单内容对应的属性
        value: {
            type: Object,
            default: ()=> ({})
        },
        // 选项卡的选项卡列表数组，数组里面数据赋值字符串，
        // 选项卡的name
        items: {
            type: Array,
            default: ()=> []
        },
        // 选项卡激活色
        color: {
            type: String,
            default: "primary"
        },
        // 使选项卡居中
        fixed_tabs: {
            type: Boolean,
            default: true
        },
        // 默认选项卡key索引
        index: {
            type: Number,
            default: 0
        },
        // 选项卡的配置对象
        deploy: {
            type: Array,
            default: ()=> []
        },
        // 声明当前选项卡是否使用在动态表单中
        declaration: {
            type: Boolean,
            default: true
        },
        // 提供外部关闭选项卡
        close: {
            type: Boolean,
            default: false
        },
        // 扩展接口，在这里创建的属性是直接赋值在v-tabls的props中
        extension: {
            type: Object,
            default: ()=> ({})
        }
    }
}