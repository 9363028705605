<template>
  <div>
    <v-tabs
      :fixed-tabs="fixed_tabs"
      :color="color"
      v-model="fullTab"
      v-if="!close"
      :eager="eagerp"
      v-bind="extension"
    >
      <v-tab v-for="item in items" :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="fullTab" v-if="!close">
      <!-- 使用eager提前渲染选项卡组件中全部内容 -->
      <v-tab-item v-for="(item, i) in deploy" :key="i" eager>
        <dynamicForm
          :deploy="{ row: item.row }"
          ref="Form"
          @change="input"
        ></dynamicForm>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import dynamicForm from "@/components/dynamicForm/dynamicForm";
import props from "./w-tabs-props.js";
export default {
  mixins: [props],
  components: {
    dynamicForm,
  },
  inject: ["form_menu"],
  data() {
    return {
      tab: 0,
      interior: false,
      memory: [],
    };
  },
  computed: {
    fullTab: {
      get() {
        return this.tab;
      },
      set(v) {
        let obj = this.$refs.Form[this.tab].form;
        // 判断当前选项是否没赋值过
        if (!this.memory[this.tab]) {
          this.memory[this.tab] = JSON.parse(JSON.stringify(obj));
        }
        this.tab = v;
      },
    },
    eagerp() {
      if (this.eager) {
        return true;
      }
      return false;
    }
  },
  methods: {
    // 接收表单修改回调的事件
    input(array) {
      // 判断当前缓存数组中有没有赋值过记录, 并且判断新修改的内容是否不一样
      let memory = this.memory[this.tab];
      if (memory && memory[array[0]] != array[1]) {
        memory[array[0]] = JSON.parse(JSON.stringify(array[1]));
      }
    },
    verification(bool) {
      if (bool && this.$refs.Form) {
        let validate;
        let jianXie = {

        }
        this.$refs.Form.some((form, index) => {
          validate = form.validate();
          // console.log(validate)
          // 判断是否为false，只要有一个有false说明还有一个选项卡没满足
          // 必填项
          if (validate.done === false) {
            // 移动到未完成必填项的选项卡
            this.tab = index;

            // 返回true是想让它停止循环，不代表validate.done全是true
            return true;
          } else {
            // 把每个选项卡里面结果保存下来备用
            // console.log(validate)
            jianXie[index] = validate.form;
            return false;
          }
        });
        // 判断当前选项卡中的内容是不是全部通过必填项判断
        console.log(validate.done, bool)
        if (validate.done) {
          // this.memory[this.tab] = validate.form;
          console.log(this.memory, jianXie)
          for (let i = 0; i < this.deploy.length; i++) {
            // 寻找缓存数组有哪个键为空
            if (!this.memory[i]) {
              Object.assign(this.form_menu.validate.form, jianXie[i]);
            }else {
              Object.assign(this.form_menu.validate.form,this.memory[i])
            }
          }
        } else {
          // 选项卡中存在尚未完成的表单必填项元素，这里强制修改成功状态为失败
          this.form_menu.interior = false;
        }
      }
    },
  },
  watch: {
    index: {
      handler(i) {
        this.tab = i;
      },
    },
    "form_menu.interior": {
      handler(v) {
        this.verification(v);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
</style>