<template>
	<div>
		<common-table
				:itemScope="['status', 'useObject']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				@search="search"
				@remove="remove"
				@save="save"
				@edit="edit"
				ref="ct"
				:rightConfig="rightConfig"
				:leftConfig="leftConfig"
				:listDeploy="listDeploy"
				:btnActionEvent="btnActionEvent"
				@otherTableOperate="otherTableOperate"
				pageTitle="规则"
				@beforeOpenAddDialog="beforeOpenAddDialog"
				@otherRightOperate="otherRightOperate"
		>
			<template v-slot:status="{ item }">
				<w-switches
						inset
						label=""
						color="primary"
						@change="updateStatus(item.sqlid)"
						v-model="item.status" hide-details dense/>
			</template>
			<template v-slot:useObject="{ item }">
				<v-icon class="iconfont ml-2" color="primary" size="24" @click="getUseObject(item.code)">iconfont
					icon-chakan-fill
				</v-icon>
			</template>
		</common-table>

		<template v-if="objectDialog">
			<use-object
					:objectDialog.sync="objectDialog"
					:code="ruleCode"
					@closeObjectDialog="objectDialog=false"/>
		</template>

		<template v-if="timeDialog">
			<TimeRangeManage :timeDialog.sync="timeDialog" @closeTimeDialog="timeDialog = false"/>
		</template>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/productManage/ruleManage.js';
import commonData from "@/api/common-data.js";
import useObject from './use-object.vue';
import wTabs from '@/components/w-tabs/w-tabs.vue';
import title from "./title.vue";
import timeLimit from './time-limit.vue';
import weekLimit from './week-limit.vue';
import refundLimit from "./refund.vue";
import TimeRangeManage from "./timeRange-manage";

export default {
	mixins: [commonCurd],
	components: {TimeRangeManage, useObject},
	data() {
		return {
			api,
			timeDialog: false,
			ruleCode: '',
			objectDialog: false,
			// 定义右侧按钮
			rightConfig: [
				{label: '时段管理', icon: 'shijian', event: 'timeRangeManage'},
				{label: '新建', icon: 'zengjia', event: 'add'}
			],
			btnActionEvent: [
				{
					event: 'editItem',
					icon: 'bianji2-fill',
					color: 'primary',
					tooltip: '编辑'
				},
				{
					event: 'copy',
					icon: 'fuzhi-fill',
					color: 'primary',
					tooltip: '复制'
				}
				, {
					event: 'deleteItem',
					icon: 'shanchu-fill',
					color: 'var(--v-redness-base)',
					tooltip: '删除'
				}
			],
			// 定义左侧搜索框
			leftConfig: [
				{type: 'input', label: '输入规则代码/名称', property: 'searchkey'},
				{
					type: 'select',
					label: '选择状态',
					property: 'status',
					items: [{code: true, desc: '启动'}, {code: false, desc: '关闭'}]
				},
				{type: 'select', label: '业务类型', property: 'type', items: []}
			],
			// 表格头部字段
			headers: [
				{text: '规则代码', value: 'code'},
				{text: '规则名称', value: 'description'},
				{text: '业务类型', value: 'type'},
				{text: '状态', value: 'status'},
				{text: '使用对象', value: 'useObject'},
				{text: '创建时间', value: 'createtime'},
				{text: '规则说明', value: 'info', width: '200px'},
				{text: '操作', value: 'actions', width: 120}
			],
			// 表单字段
			listDeploy: {
				row: [
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'type',
						connector: {
							props: {
								required: true,
								label: "业务类型",
								arrayType: false,
								items: []
							},
							on: {
								especially: (v) => {
									// this.listDeploy.row[2].connector.style.opacity = (v === 'T') ? 1 : 0
									this.$store.state.rule_object_type = v
								}
							}
						},
					},
					{
						cols: 3,
						subassembly: "WSwitches",
						property: 'status',
						disabledDeleteDefault: true,
						connector: {
							props: {
								label: '状态',
								inset: true,
								dense: true
							}
						},
						default: true
					},
					// {
					// 	cols: 3,
					// 	subassembly: "WSwitches",
					// 	property: 'autocancel',
					// 	disabledDeleteDefault: true,
					// 	connector: {
					// 		props: {
					// 			label: '发码失败是否自动退款',
					// 			inset: true,
					// 			dense: true
					// 		},
					// 		style: {
					// 			opacity: 0
					// 		},
					// 	},
					// 	default: false
					// },
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'code',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								required: true,
								label: "规则代码"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'description',
						connector: {
							props: {
								required: true,
								label: "规则名称"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'info',
						connector: {
							props: {
								label: "规则说明"
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'policys',
						connector: {
							props: {
								required: false,
								label: "时段规则",
								items: [],
								chips: true
							}
						}
					},
					{
						cols: 12,
						subassembly: wTabs,
						merge: true,
						connector: {
							props: {
								items: ["购买规则", "售后规则"],
								deploy: [
									{
										row: [
											{
												cols: 12,
												subassembly: title,
												connector: {
													props: {
														clazz: 'mt-6',
														title: '购买数量限制',
														subTitle: '（限制订单单笔购买的数量，最小数为1，最大数为x）'
													}
												}
											},
											{
												subassembly: 'VTextField',
												cols: 6,
												property: 'limitmin',
												connector: {
													props: {
														label: '最小数值',
														type: 'number',
														required: true
													}
												}
											},
											{
												subassembly: 'VTextField',
												cols: 6,
												property: 'limitmax',
												connector: {
													props: {
														label: '最大数值',
														type: 'number',
														required: true
													}
												}
											},
											{
												cols: 12,
												subassembly: title,
												connector: {
													props: {
														title: '提前购买天数',
														subTitle: '（限制提前购买日期范围，0为当天）'
													}
												}
											},
											{
												subassembly: 'VTextField',
												cols: 6,
												property: 'inadvancemin',
												connector: {
													props: {
														label: '最小数值',
														type: 'number',
														required: true
													}
												}
											},
											{
												subassembly: 'VTextField',
												cols: 6,
												property: 'inadvancemax',
												connector: {
													props: {
														label: '最大数值',
														type: 'number',
														required: true
													}
												}
											},
											{
												subassembly: timeLimit,
												property: 'selltime',
												connector: {
													props: {
														required: true
													}
												},
												cols: 12
											},
											{
												subassembly: weekLimit,
												property: 'limitweek',
												cols: 12
											},
											{
												subassembly: title,
												cols: 12,
												connector: {
													props: {
														title: '购买用户限制',
														subTitle: '（限制同一用户单日购买该商品最大数量）'
													}
												}
											},
											{
												cols: 3,
												subassembly: "WSwitches",
												disabledDeleteDefault: true,
												property: 'limituser',
												connector: {
													props: {
														label: '用户限制',
														inset: true,
														dense: true
													},
													style: {
														color: '#666666',
														marginTop: '6px'
													},
													on: {
														especially: (v) => {
															this.listDeploy.row[6].connector.props.deploy[0].row[9].default = v
															this.listDeploy.row[6].connector.props.deploy[0].row[10].connector.style.display = v ? 'block' : 'none'
														}
													}
												},
												default: true
											},
											{
												subassembly: 'VTextField',
												cols: 6,
												property: 'limitnum',
												connector: {
													props: {
														label: '限制数量',
														type: 'number',
														required: false
													},
													style: {
														display: 'none'
													}
												}
											},
											{
												cols: 10,
												subassembly: "WSwitches",
												disabledDeleteDefault: true,
												property: 'buyaudit',
												connector: {
													props: {
														label: '人工确认',
														inset: true,
														dense: true,
														required: true
													},
													style: {
														'font-size': '18px',
														'font-weight': 'bold'
													}
												},
												default: true
											}
										]
									},
									{
										row: [
											{
												subassembly: title,
												cols: 12,
												connector: {
													props: {
														clazz: 'mt-6',
														title: '退单限制',
														subTitle: '（购票后是否允许限制退单及退单规则）'
													}
												}
											},
											{
												cols: 12,
												subassembly: refundLimit,
												merge: true,
												property: 'refundForm'
											}
										]
									}
								]
							}
						}
					}
				]
			}
		}
	},
	methods: {
		// 使用对象
		getUseObject(code) {
			this.ruleCode = code;
			this.objectDialog = true;
		},
		getCommonSelect() {
			this.axios.post(
					commonData.select,
					commonData.getSelectList({keyname: "ruletype,rulespolicy"})
			).then((res) => {
						if (res?.data) {
							this.leftConfig[2].items = res.data[0].values;
							this.listDeploy.row[0].connector.props.items = res.data[0].values;
							this.listDeploy.row[5].connector.props.items = res.data[1].values;
						}
					}
			);
		},
		beforeOpenAddDialog() {
			this.getCommonSelect();
			if (this.listDeploy.sqlid === 0) {
				this.listDeploy.row[6].connector.props.deploy.forEach((d) => {
					for (const obj of d.row) {
						this.deleteFieldProperty(obj);
					}
				});
			}
		},
		// 保存之前的操作
		beforeSave(form) {
			form.limittime = !!form.selltime;
			Object.assign(form, form.refundForm);
		},
		beforeEdit(form) {
			this.listDeploy.row[6].connector.props.deploy.forEach((d) => {
				for (const obj of d.row) {
					this.deleteFieldProperty(obj);
					let val = form[obj.property];
					if (val || val === 0) {
						obj.default = val;
					}
					if (obj.property === 'limituser') {
						this.listDeploy.row[6].connector.props.deploy[0].row[10].connector.style.display = val ? 'block' : 'none'
					} else if (obj.property === 'refundForm') {
						let f = JSON.parse(JSON.stringify(form));
						obj.default = {
							refundstatus: f.refundstatus,
							// 退单条件 ”0“：不限制 1：提前xx天
							isrefund: f.isrefund,
							// 手续费规则：1 固定手续费 2 按比例
							strategytype: f.strategytype,
							// 是否需要手续费
							strategystaus: f.strategystaus,
							// 提前天数
							refundday: f.refundday,
							// 是否需要审核
							lauditing: f.lauditing,
							autocancel: f.autocancel,
							autorefundtikcet: f.autorefundtikcet,
							// 人工限制确认
							// lsecond: f.lsecond,
							strategyjson: f.strategyjson,
							refundretain: f.refundretain,
							// 免费取消时限
							refundfreetime: f.refundfreetime
						};
					}
				}
			});
		},
		// 删除字段的属性
		deleteFieldProperty(obj) {
			if (obj.subassembly !== 'div') {
				delete obj.default;
			}
			if (obj.connector?.props) {
				delete obj.connector.props.value;
			}
		},
		otherTableOperate(event, item) {
			if (event === this.btnActionEvent[1].event) {
				this.$refs.ct.openCopyDialog(item, ["code", "description"], 'code')
			}
		},
		otherRightOperate(event) {
			if (event === 'timeRangeManage')
				this.timeDialog = true
		}
	},
	mounted() {
		this.getCommonSelect();
	}
}
</script>

<style scoped lang="scss">

</style>
