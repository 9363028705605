<template>
	<v-form>
		<w-switches label="允许退单" v-model="form.refundstatus" @change="changeRefundStatus"/>
		<template v-if="form.refundstatus">
			<v-simple-table>
				<tbody>
				<tr>
					<td style="width: 160px">
						<div class="c-title">退单条件</div>
					</td>
					<td>
						<v-radio-group :column="false" style="margin-top: 0" hide-details v-model="form.isrefund">
							<v-radio label="不限条件退单" :value="0"></v-radio>
							<v-radio label="提前xx小时" :value="1" class="ml-4"></v-radio>
						</v-radio-group>
					</td>
				</tr>
				<template v-if="form.isrefund === 1">
					<tr>
						<td></td>
						<td>
							<div>
								提前
								<v-text-field type="number" v-model="form.refundday" class="c-text-input"></v-text-field>
								小时可退
							</div>
						</td>
					</tr>
				</template>
				<tr>
					<td>
						<div class="c-title">是否需要审核</div>
					</td>
					<td>
						<w-switches label="" :dense="true" v-model="form.lauditing"/>
					</td>
				</tr>
				<tr>
					<td>
						<div class="c-title">夜审自动审核</div>
					</td>
					<td>
						<w-switches label="" :dense="true" v-model="form.autocancel"/>
					</td>
				</tr>
				<tr v-if="$store.state.rule_object_type === 'T'">
					<td>
						<div class="c-title">过期未使用自动退款</div>
					</td>
					<td>
						<w-switches label="" :dense="true" v-model="form.autorefundtikcet"/>
					</td>
				</tr>
<!--				<tr>-->
<!--					<td>-->
<!--						<div class="c-title">人工限制确认</div>-->
<!--					</td>-->
<!--					<td>-->
<!--						<w-switches label="" :dense="true" v-model="form.lsecond"/>-->
<!--					</td>-->
<!--				</tr>-->
				<tr>
					<td>
						<div class="c-title">是否需要手续费</div>
					</td>
					<td>
						<w-switches label="" :dense="true" v-model="form.strategystaus"/>
					</td>
				</tr>
				<template v-if="form.strategystaus">
					<tr>
						<td>
							<div class="c-title">手续费规则</div>
						</td>
						<td>
							<v-radio-group :column="false" style="margin-top: 0" hide-details v-model="form.strategytype">
								<v-radio label="固定手续费" value="1"></v-radio>
								<v-radio label="比例值" class="ml-4" value="2"></v-radio>
							</v-radio-group>
						</td>
						<td>
							<span>退款小数位数为</span>
							<v-text-field
									class="c-text-input"
									v-model="form.refundretain"
									type="number"
							>
							</v-text-field>
							<span>位（建议位数0/1/2）</span>
						</td>
					</tr>
					<template v-for="(row, index) in strategyJsonForm">
						<tr :key="index">
							<td>
								<template v-if="index === 0">
									<div class="c-title">退单手续费设置</div>
								</template>
							</td>
							<td>
								<div>
									提前
									<v-text-field
											class="c-text-input"
											v-model="row.strategy"
											>
									</v-text-field>
									小时之后，收
									<v-text-field
											v-model="row.debit"
											class="c-text-input">
									</v-text-field>
									{{ form.strategytype === '1' ? '元' : '%' }}
								</div>
							</td>
						</tr>
					</template>
					<tr>
						<td>
							<div class="c-title">免费取消时限</div>
						</td>
						<td>
							<span>支付后</span>
							<v-text-field
									class="c-text-input"
									v-model="form.refundfreetime"
									type="number"
							>
							</v-text-field>
							<span>分钟内免费取消订单</span>
						</td>
					</tr>
				</template>
				</tbody>
			</v-simple-table>
		</template>
	</v-form>
</template>

<script>
export default {
	// 退单限制,
	data() {
		return {
			object_type: "",
			canRefund: true,
			initForm: {},
			form: {
				// 允许退单
				refundstatus: true,
				// 退单条件 ”0“：不限制 1：提前xx天
				isrefund: 0,
				// 手续费规则：1 固定手续费 2 按比例
				strategytype: '1',
				// 是否需要手续费
				strategystaus: true,
				// 提前天数
				refundday: '',
				// 是否需要审核
				lauditing: true,
				autocancel: false,
				autorefundtikcet: false,
				// 人工限制确认
				// lsecond: true,
				strategyjson: '',
				// 退款小数位数
				refundretain: '',
				// 免费取消时限
				refundfreetime: ''
			},
			strategyJsonForm: [{}, {}, {}]
		}
	},
	methods: {
		changeRefundStatus(v) {
			if (!v) {
				this.form = JSON.parse(JSON.stringify(this.initForm));
			}
		}
	},
	watch: {
		form: {
			handler(v) {
				this.$nextTick(() => {
					this.$emit('change', v);
				});
			},
			deep: true,
			immediate: true,
		},
		value: {
			handler(v) {
				if (v?.refundstatus) {
					this.form = Object.assign({}, v);
					if (this.form.strategyjson) {
						this.strategyJsonForm = JSON.parse(this.form.strategyjson);
					}
				}
			},
			immediate: true
		},
		'form.isrefund': {
			handler(v) {
				if (v === 1) {
					this.form.refundday = '';
				}
			}
		},
		strategyJsonForm: {
			handler(v) {
				this.form.strategyjson = JSON.stringify(v);
			},
			deep: true
		}
	},
	mounted() {
		this.initForm = JSON.parse(JSON.stringify(this.form));
	},
	props: {
		value: {
			type: Object,
			default: () => ({})
		}
	}
}
</script>

<style scoped lang="scss">
.c-title {
	font-size: 14px;
	font-weight: bold;
}

.c-text-input {
	display: inline-block;
	width: 80px;
}

::v-deep {
	.v-data-table__wrapper > table > tbody {
		tr > td {
			padding: 0;
			border-bottom: none !important;
		}

		tr:hover {
			background-color: #ffffff !important;
		}

		.v-input--selection-controls__ripple {
			width: 0;
		}
	}

	.v-label {
		color: #666666 !important;
	}
}
</style>
