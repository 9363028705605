<template>
	<exhibition-data-box
			:dialog.sync="dialog"
			title="使用对象"
			:code="code"
			:contentHeight="fixedHeight + 100"
	>
		<common-table
				:headers="headers"
				:listData="listData"
				:loading="loading"
				:leftConfig="leftConfig"
				:normalPage="false"
				@search="search"
				:dialogTable=true
				ref="ct"
				:fixedHeight="fixedHeight"
		/>
	</exhibition-data-box>
</template>

<script>
import exhibitionDataBox from '@/components/exhibition-data-box/exhibition-data-box.vue';
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/productManage/rule-useObject.js';

export default {
	components: {exhibitionDataBox},
	mixins: [commonCurd],
	props: {
		code: {
			type: String,
			default: () => ''
		},
		objectDialog: {
			type: Boolean,
			default: () => false
		}
	},
	data() {
		return {
			api,
			fixedHeight: 0,
			leftConfig: [
				{type: 'input', label: '输入产品代码/名称', property: 'searchkey'},
			],
			headers: [
				{text: '规则名称', value: 'description'},
				{text: '产品代码', value: 'productcode'},
				{text: '产品名称', value: 'productdesc'},
				{text: '业务类型', value: 'type'}
			],
			dialog: false
		}
	},
	methods: {
		createdExtend() {
			this.form.searchkey = this.code;
		}
	},
	watch: {
		objectDialog: {
			handler(v) {
				if (v) {
					this.dialog = true;
				}
			},
			immediate: true
		},
		dialog: {
			handler(v) {
				if (!v) {
					this.$emit("closeObjectDialog");
				}
			}
		}
	},
	mounted() {
		this.fixedHeight = document.documentElement.clientHeight * 0.6;
	}
}
</script>

<style scoped>

</style>
