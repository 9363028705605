<template>
	<div>
		<c-title title="购买星期限制" sub-title="（不勾选则不限，否则限制选中星期几不可购买）"/>
		<div>
			<template v-for="(item, index) in week">
				<v-checkbox
						style="display: inline-block; margin-right: 20px"
						:label="item.label"
						:key="index"
						:input-value="items.includes(item.key)"
						@change="change($event, item.key)"
						/>
			</template>
		</div>
	</div>
</template>

<script>
import cTitle from "./title.vue";

export default {
	// 购买星期限制
	name: "week-limit",
	components: {cTitle},
	props: {
		value: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			week: [
				{key: '1', label: '星期一'},
				{key: '2', label: '星期二'},
				{key: '3', label: '星期三'},
				{key: '4', label: '星期四'},
				{key: '5', label: '星期五'},
				{key: '6', label: '星期六'},
				{key: '0', label: '星期日'}
			],
			items: []
		}
	},
	methods: {
		change(e, v) {
			if (!e) {
				this.removeArrays(v);
			} else {
				this.items.push(v);
			}
			this.$emit('change', this.items.join(','));
		},
		removeArrays(i) {
			let items = [];
			this.items.forEach((item) => {
				if (item !== i) {
					items.push(item);
				}
			});
			this.items = items;
		}
	},
	watch: {
		value: {
			handler(v) {
				if (v) {
					this.items = v.split(',');
				}
			},
			immediate: true
		}
	}
}
</script>

<style scoped lang="scss">
::v-deep {
	.v-label {
		color: #666666 !important;
	}
}
</style>
